/** @format */

// ** React Imports
import { useState, createContext } from "react";

// ** Intl Provider Import
import { IntlProvider } from "react-intl";

// ** User Language Data
import userMessagesEn from "@src/assets/data/locales/en.json";
import userMessagesAr from "@src/assets/data/locales/ar.json";
// ** Menu msg obj
const menuMessages = {
  en: { ...userMessagesEn },
  ar: { ...userMessagesAr },
};

// ** Create Context
const Context = createContext();

const IntlProviderWrapper = ({ children }) => {
  // ** States
  const [locale, setLocale] = useState(localStorage.getItem("lang") || "en");
  const [messages, setMessages] = useState(menuMessages[locale]);

  // ** Switches Language
  const switchLanguage = (lang) => {
    localStorage.setItem("lang", lang);
    setLocale(lang);
    setMessages(menuMessages[lang]);
  };

  return (
    <Context.Provider value={{ locale, switchLanguage }}>
      <IntlProvider
        key={locale}
        locale={locale}
        messages={messages}
        defaultLocale="en"
      >
        {children}
      </IntlProvider>
    </Context.Provider>
  );
};

export { IntlProviderWrapper, Context as IntlContext };
