/** @format */

// **  Initial State
const initialState = {
  userData: {},
  token: "",
  logginIn: false,
  user: {},
  role: "",
  context_id: "",
  auth_object: {},
};

const authReducer = (state = initialState, action) => {
  console.log({ action });
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        token: action.token,
        userData: action.userData,
      };
    case "LOGOUT":
      localStorage.removeItem("role");
      return { ...state, userData: {}, token: "", logginIn: false };
    case "ADD_USER_DATA":
      return {
        ...state,
        userData: action.userData,
      };
    case "SET_USER":
      return {
        ...state,
        user: action.user,
      };
    case "SET_ROLE":
      localStorage.setItem("role", action.role);
      return {
        ...state,
        role: action.role,
      };
    case "SET_CONTEXT_ID":
      localStorage.setItem("context_id", action.context_id);
      return {
        ...state,
        context_id: action.context_id,
      };
    case "SET_AUTH_OBJECT":
      localStorage.setItem("auth_object", JSON.stringify(action.auth_object));
      return {
        ...state,
        auth_object: action.auth_object,
      };
    default:
      return state;
  }
};

export default authReducer;
